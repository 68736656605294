import { createEventHandler } from '../../utils/pollard'
import { getStorage, StorageType } from '../../utils/storage'

interface Payload {
  storage: string
  key: string
  value: unknown
}

export default () => {
  return createEventHandler((payload: Payload) => {
    const { storage, key, value } = payload
    if (!storage || !key) {
      return
    }
    getStorage(storage as StorageType).setItem(key, JSON.stringify(value))
  })
}
