import { createClientResponse } from '../../utils/pollard'
import { getStorage, StorageType } from '../../utils/storage'

export const DEFAULT_SESSION_STORAGE_KEY = 'IMOD_CURRENT_NAVIGATION'

interface Payload {
  key: string
  storageName: string
}

export default () => {
  return createClientResponse((payload: Payload) => {
    const { key, storageName } = payload
    const storage = getStorage(storageName as StorageType)
    const currentData = JSON.parse(storage.getItem(key || '') || '{}')

    return currentData
  })
}
