import { Environment } from '../../utils/env'
import { appendTrackingFrame } from '../../utils/iframe'
import { createEventHandler } from '../../utils/pollard'

export const AFFILIATE_NAMESPACE = '/affiliate:'

export function isAffiliateEvent(event: string) {
  return event.startsWith(AFFILIATE_NAMESPACE)
}

export function withoutNamespace(event: string) {
  return event.substring(AFFILIATE_NAMESPACE.length)
}

export interface TrackingEventPayload {
  event: string
  [name: string]: string | number | undefined
}

export default (environment: Environment, deactivateHubble: boolean) =>
  createEventHandler(({ event, ...data }: TrackingEventPayload) => {
    if (!isAffiliateEvent(event) || deactivateHubble) {
      return
    }

    const disposeFrame = appendTrackingFrame(
      environment,
      withoutNamespace(event),
      data,
    )

    // FIXME: Remove after 10 seconds for now, but it would be nicer
    // to have the trackingframe post to parent that it finished.
    setTimeout(disposeFrame, 10 * 1000)
  })
