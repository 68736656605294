import {
  ClientResponse,
  EventListener,
  RegisteredEventName,
} from '@finanzcheck/catalyst-pollard'
import { CSSProperties } from 'react'
import { getClient, muteAll } from '../pollard'
import actionHandler from '../../events/action'
import backlinkHandler from '../../events/backLink'
import changeNavigationHandler from '../../events/changeNavigation'
import changeViewportHandler from '../../events/changeViewport'
import updateQueryParamsHandler from '../../events/updateQueryParams'
import readyHandler from '../../events/ready'
import scrollToHandler from '../../events/scrollTo'
import scrollTopHandler from '../../events/scrollTop'
import setCurrentNavigationHandler from '../../events/setCurrentNavigation'
import getCurrentNavigationHandler from '../../events/getCurrentNavigation'
import getParentStorageHandler from '../../events/getParentStorageHandler'
import getParentFrameUrlHandler from '../../events/getParentFrameUrl'
import setParentStorageHandler from '../../events/setParentStorageHandler'
import getIsEmailFieldDisabled from '../../events/getIsEmailFieldDisabled'
import trackingHandler, { TrackingEventPayload } from '../../events/tracking'
import updateCurrentImodTraversalHandler from '../../events/updateCurrentImodTraversal'
import { buildUrl, getEventUrl } from '../url'
import { Environment } from '../env'
import getTenantContextHandler from '../../events/getTenantContext'
import handleRedirect from '../../events/redirect'
import handleOpenWindow from '../../events/openWindow'

export interface IframeAttributes {
  src: string
  iframeName: string
  style?: CSSProperties
}

const DEFAULT_IFRAME_STYLES: CSSProperties = {
  border: 'none',
  position: 'relative',
}

export function withStyles(
  iframe: HTMLIFrameElement,
  styles: CSSProperties = {},
) {
  iframe.setAttribute(
    'style',
    Object.entries({
      ...DEFAULT_IFRAME_STYLES,
      ...styles,
    })
      .map(([name, value]) => `${name}:${value}`)
      .join(';'),
  )

  return iframe
}

export function appendIframe(
  parent: HTMLElement,
  { src, iframeName, style = {} }: IframeAttributes,
  additionalUrlParams: Record<string, string | number | undefined> = {},
) {
  const iframe = withStyles(document.createElement('iframe'), style)

  iframe.src = buildUrl(src, additionalUrlParams)
  iframe.name = iframeName

  parent.appendChild(iframe)

  return iframe
}

export type EventListeners = Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<RegisteredEventName, EventListener<any>>
>

export type ClientResponses = Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<RegisteredEventName, ClientResponse<string, any, any>>
>

export interface IframeOptions extends IframeAttributes {
  env: Environment
  backUrl?: string
  deactivateHubble?: boolean
  overrides?: {
    listeners?: EventListeners
    responses?: ClientResponses
  }
}

export function createPollardIframe(
  element: HTMLElement,
  {
    env,
    src,
    iframeName,
    overrides = {},
    backUrl,
    style = {},
    deactivateHubble = false,
  }: IframeOptions,
) {
  const iframe = appendIframe(
    element,
    {
      src,
      iframeName,
      style: {
        width: '100%',
        minHeight: '100%',
        ...style,
      },
    },
    { backUrl },
  )

  const { listeners = {}, responses = {} } = overrides

  const listenerMap: EventListeners = {
    [RegisteredEventName.changeNavigation]: changeNavigationHandler(),
    [RegisteredEventName.changeViewport]: changeViewportHandler(iframe),
    [RegisteredEventName.updateQueryParams]: updateQueryParamsHandler(),
    [RegisteredEventName.scrollTo]: scrollToHandler(iframe),
    [RegisteredEventName.scrollTop]: scrollTopHandler(iframe),
    [RegisteredEventName.setCurrentNavigation]: setCurrentNavigationHandler(),
    [RegisteredEventName.tracking]: trackingHandler(env, deactivateHubble),
    [RegisteredEventName.setParentStorageData]: setParentStorageHandler(),
    [RegisteredEventName.redirect]: handleRedirect(),
    [RegisteredEventName.openWindow]: handleOpenWindow(),
    ...listeners,
  }

  if (backUrl) {
    listenerMap[RegisteredEventName.backLink] = backlinkHandler(backUrl)
  }

  const responseMap: ClientResponses = {
    [RegisteredEventName.action]: actionHandler(),
    [RegisteredEventName.ready]: readyHandler(iframeName),
    [RegisteredEventName.updateCurrentImodTraversal]:
      updateCurrentImodTraversalHandler(),
    [RegisteredEventName.getTenantContext]: getTenantContextHandler(),
    [RegisteredEventName.getCurrentNavigation]: getCurrentNavigationHandler(),
    [RegisteredEventName.getParentStorage]: getParentStorageHandler(),
    [RegisteredEventName.getParentFrameUrl]: getParentFrameUrlHandler(),
    [RegisteredEventName.getIsEmailFieldDisabled]: getIsEmailFieldDisabled(),
    ...responses,
  }

  const pollardClient = getClient({ target: 'child', childName: iframeName })

  for (const [eventName, handler] of Object.entries(listenerMap)) {
    if (!handler) {
      continue
    }

    pollardClient.listenTo(eventName, handler)
  }

  for (const [eventName, response] of Object.entries(responseMap)) {
    if (!response) {
      continue
    }

    pollardClient.respondTo(eventName, response)
  }

  return () => {
    muteAll(pollardClient, listenerMap, responseMap)
    element.removeChild(iframe)
  }
}

export function appendTrackingFrame(
  environment: Environment,
  eventType: string,
  params: Omit<TrackingEventPayload, 'event'>,
) {
  const iframe = appendIframe(document.body, {
    src: getEventUrl(environment, eventType, params),
    iframeName: 'affiliate-frame',
    style: {
      position: 'absolute',
      top: '-9999px',
      minHeight: '1px',
      height: '1px',
      width: '1px',
    },
  })

  return () => {
    document.body.removeChild(iframe)
  }
}
