// eslint-disable-next-line import/no-unresolved
import 'url-search-params-polyfill'
import { Environment, getHubbleUrl } from '../env'

export function setUrlParams(params: Record<string, string | undefined>) {
  const urlParams = new URLSearchParams(window?.location?.search)

  Object.entries(params).forEach(([name, value]) => {
    if (value === undefined) {
      urlParams.delete(name)
      return
    }

    urlParams.set(name, value)
  })

  window.history.replaceState(
    null,
    '',
    `${window.location.pathname}?${urlParams.toString()}`,
  )
}

export function buildUrl(
  src: string,
  params: Record<string, string | number | undefined> = {},
) {
  const url = new URL(src)
  const searchParams = url.searchParams.toString()

  let appendSymbol = '?'

  if (searchParams) {
    appendSymbol = '&'
  }

  const paramsString = Object.entries(params)
    .filter(([, value]) => value !== undefined)
    .map(([name, value]) => [name, encodeURIComponent(value!)])
    .map(([name, value]) => `${name}=${value}`)
    .join('&')

  if (paramsString) {
    return `${src}${appendSymbol}${paramsString}`
  }

  return src
}

export function getEventUrl(
  environment: Environment,
  event: string,
  params: Record<string, string | number | undefined> = {},
) {
  // Remove unnecessary query param on hubble url
  // Reason: https://jira.atl.finanzcheck.de/browse/ANCH-1333
  //    The hubble request url excedded the max (2048) chars a browser can support
  let preUrl = `${window.location.origin}${window.location.pathname}`
  const queryString = window?.location?.search
  if (!!queryString) {
    const urlParams = new URLSearchParams(queryString)
    urlParams.delete('backUrl')
    preUrl += `?${urlParams.toString()}`
  }

  return buildUrl(`${getHubbleUrl(environment)}/p/${event}`, {
    ...params,
    ref: window.document.referrer,
    preUrl, // This is used for 3rd party tracking pixels, e.g. reading URL parameters such as `at_gd`..
  })
}
