import { setUrlParams } from '../../utils/url'
import { createEventHandler } from '../../utils/pollard'

export default () =>
  createEventHandler((payload: { route?: string }) => {
    if (!payload?.route) {
      return
    }

    setUrlParams(payload)
  })
