import { createClientResponse } from '../../utils/pollard'

export default () => {
  return createClientResponse(() => {
    const SMAVA_DOMAINS = ['smava.de', 'smava-shared.de', 'smava-stage.de']
    const FINANZCHECK_DOMAINS = [
      'finanzcheck.de',
      'finanzcheck-shared.de',
      'finanzcheck-stage.de',
    ]

    if (SMAVA_DOMAINS.some((domain) => window.location.href.includes(domain)))
      return 'smava'
    if (
      FINANZCHECK_DOMAINS.some((domain) =>
        window.location.href.includes(domain),
      )
    )
      return 'finanzcheck'

    return 'external'
  })
}
