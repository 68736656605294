import {
  EventListener,
  ClientResponse,
  Client,
  ClientConfig,
} from '@finanzcheck/catalyst-pollard'
import { ClientResponses, EventListeners } from '../iframe'

export function createEventHandler<T>(
  handler: (data: T) => void,
): EventListener<T> {
  return (data) => handler(data)
}

export function createClientResponse<E extends string, T, R>(
  handler: ClientResponse<E, T, R>,
): ClientResponse<E, T, R> {
  return (data) => handler(data)
}

let parentClient: Client
const childClients: Record<string, Client> = {}

function getParentClient(config: ClientConfig) {
  if (!parentClient) {
    parentClient = new Client(config)
  }

  return parentClient
}

function getChildClient(config: ClientConfig) {
  const childName = config.childName || '_first'
  if (!childClients[childName]) {
    childClients[childName] = new Client(config)
  }

  return childClients[childName]
}

export function getClient(config: ClientConfig) {
  if (config.target === 'parent') {
    return getParentClient(config)
  }

  return getChildClient(config)
}

export function muteAll(
  client: Client,
  listeners: EventListeners,
  responses: ClientResponses,
) {
  for (const [eventName] of Object.entries(listeners)) {
    client.mute(eventName)
  }

  for (const [eventName] of Object.entries(responses)) {
    client.mute(eventName)
  }
}
