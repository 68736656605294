export type Environment =
  | 'local'
  | 'shared_finanzcheck'
  | 'stage_finanzcheck'
  | 'production_finanzcheck'
  | 'shared_smava'
  | 'stage_smava'
  | 'production_smava'

const hubbleUrl: Record<Environment, string> = {
  local: 'http://localhost:8058/v1',
  shared_finanzcheck: 'https://hubble.finanzcheck-shared.de/v1',
  stage_finanzcheck: 'https://hubble.finanzcheck-stage.de/v1',
  production_finanzcheck: 'https://hubble.finanzcheck.de/v1',
  shared_smava: 'https://hubble.smava-shared.de/v1',
  stage_smava: 'https://hubble.smava-stage.de/v1',
  production_smava: 'https://hubble.smava.de/v1',
}

export function getHubbleUrl(environment: Environment) {
  return hubbleUrl[environment]
}
