import { setUrlParams } from '../../utils/url'
import { createEventHandler } from '../../utils/pollard'

export default () =>
  createEventHandler(
    (payload: {
      amount?: string
      category?: string
      duration?: string
      initialPayment?: string
      vehiclePrice?: string
    }) => {
      if (
        !payload?.amount &&
        !payload?.category &&
        !payload?.duration &&
        !payload?.initialPayment &&
        !payload?.vehiclePrice
      ) {
        return
      }

      setUrlParams(payload)
    },
  )
