import { getMemoryStorage } from './memory'

export const STORAGE_KEY_CURRENT_NAVIGATION = 'CURRENT_FRAME_NAVIGATION'

export type StorageType = 'localStorage' | 'sessionStorage'

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 */
export function isStorageAvailable(type: StorageType): boolean {
  let storage

  try {
    storage = window[type]
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)

    return true
  } catch (e) {
    return !!(
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

const storageAvailability: Partial<Record<StorageType, boolean>> = {}

export function getStorage(type: StorageType) {
  if (storageAvailability[type] === undefined) {
    storageAvailability[type] = isStorageAvailable(type)
  }

  return storageAvailability[type] ? window[type] : getMemoryStorage()
}
