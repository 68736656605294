import { createEventHandler } from '../../utils/pollard'

interface Payload {
  url: string
}

export default () => {
  return createEventHandler((payload: Payload) => {
    if (!payload?.url) {
      return
    }

    window.open(payload.url)
  })
}
