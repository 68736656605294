import { getStorage, STORAGE_KEY_CURRENT_NAVIGATION } from './utils/storage'
import { createPollardIframe, IframeOptions } from './utils/iframe'
import { Environment } from './utils/env'

function createIframe(
  element: HTMLElement,
  {
    src,
    iframeName,
    overrides = {},
    backUrl,
    deactivateHubble,
    env,
  }: IframeOptions,
) {
  const lastNavigation = JSON.parse(
    getStorage('sessionStorage').getItem(STORAGE_KEY_CURRENT_NAVIGATION) ||
      '{}',
  ).url

  if (!lastNavigation && !src) {
    throw new Error('The URL for the iframe is missing.')
  }

  return createPollardIframe(element, {
    env: env || ('__ENVIRONMENT__' as Environment),
    src: lastNavigation || src,
    iframeName,
    style: {
      width: '100%',
      minHeight: '100%',
    },
    backUrl,
    overrides,
    deactivateHubble,
  })
}

export default {
  load: createIframe,
}
