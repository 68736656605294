import { createEventHandler } from '../../utils/pollard'
import { getStorage } from '../../utils/storage'

export const DEFAULT_SESSION_STORAGE_KEY = 'IMOD_CURRENT_NAVIGATION'

interface StorageOptions {
  key?: string
}

export default (storageOptions: StorageOptions = {}) => {
  const { key = DEFAULT_SESSION_STORAGE_KEY } = storageOptions

  return createEventHandler((payload: { url?: string }) => {
    if (!payload.url) {
      return
    }

    const before = getStorage('sessionStorage').getItem(key)

    if (before) {
      const data = JSON.parse(before)

      getStorage('sessionStorage').setItem(
        key,
        JSON.stringify({
          ...data,
          ...payload,
        }),
      )

      return
    }

    getStorage('sessionStorage').setItem(key, JSON.stringify(payload))
  })
}
