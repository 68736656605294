import { createClientResponse } from '../../utils/pollard'
import { getStorage } from '../../utils/storage'
import { DEFAULT_SESSION_STORAGE_KEY } from '../setCurrentNavigation'

interface StorageOptions {
  key?: string
}

export default (storageOptions: StorageOptions = {}) => {
  const { key = DEFAULT_SESSION_STORAGE_KEY } = storageOptions

  return createClientResponse(() => {
    const storage = getStorage('sessionStorage')
    const currentData = JSON.parse(storage.getItem(key) || '{}')

    return currentData
  })
}
