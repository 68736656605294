import { createClientResponse } from '../../utils/pollard'
import { getStorage } from '../../utils/storage'

export const DEFAULT_LOCAL_STORAGE_KEY = 'IMOD_CURRENT_TRAVERSAL_DATA'

interface StorageOptions {
  key?: string
}

interface Payload {
  requestLatestTraversal?: boolean
  [key: string]: unknown
}

export default (storageOptions: StorageOptions = {}) => {
  const { key = DEFAULT_LOCAL_STORAGE_KEY } = storageOptions

  return createClientResponse((payload: Payload = {}) => {
    const storage = getStorage('localStorage')
    const currentData = JSON.parse(storage.getItem(key) || '{}')

    if (!payload.requestLatestTraversal) {
      storage.setItem(
        key,
        JSON.stringify({
          ...currentData,
          ...payload,
        }),
      )

      return true
    }

    const traversal = storage.getItem(key)
    if (!traversal) {
      return {}
    }

    return JSON.parse(traversal)
  })
}
