import {
  broadcastToChild,
  RegisteredEventName,
} from '@finanzcheck/catalyst-pollard'
import { createClientResponse } from '../../utils/pollard'

export const OPT_OUT_COOKIE_NAME = '_ga_opt_out=true'

export default (iframeName: string) =>
  createClientResponse(() => {
    if (document.cookie.indexOf(OPT_OUT_COOKIE_NAME) !== -1) {
      broadcastToChild({
        eventName: RegisteredEventName.setOptOut,
        childName: iframeName,
        data: { generalOptOut: true },
      })
    }

    return true
  })
