export class MemoryStorage {
  private storage: Record<string, string | null> = {}

  setItem = (key: string, value: string | null) => {
    this.storage[key] = value
  }

  getItem = (key: string) => this.storage[key]

  removeItem = (key: string) => {
    delete this.storage[key]
  }

  clear = () => {
    this.storage = {}
  }

  key = (index: number) => Object.keys(this.storage)[index]
}

let memoryStorage: MemoryStorage | null = null

export function getMemoryStorage() {
  if (!memoryStorage) {
    memoryStorage = new MemoryStorage()
  }

  return memoryStorage
}
