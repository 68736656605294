import { createEventHandler } from '../../utils/pollard'

export default (iframe: HTMLIFrameElement | null) =>
  createEventHandler(({ height }: { height: number }) => {
    if (!iframe) {
      return
    }

    const extraPadding = 152
    const heightInPx = `${height}px`

    iframe.style.setProperty('min-height', heightInPx)

    if (iframe.parentElement) {
      iframe.parentElement.style.setProperty('min-height', heightInPx)
    }

    if (window.location.href.includes('smava.de') && window.parent) {
      const smavaAffiliateHeight = height + extraPadding

      window.parent.postMessage('RESIZE:' + smavaAffiliateHeight, '*')
    }
  })
