import {
  broadcastToChild,
  RegisteredEventName,
} from '@finanzcheck/catalyst-pollard'
import { createEventHandler } from '../../utils/pollard'

export default (iframe: HTMLIFrameElement | null) =>
  createEventHandler(() => {
    if (!iframe) {
      return
    }

    broadcastToChild({
      eventName: RegisteredEventName.scrollTop,
      childName: iframe.name,
      data: {
        position: (document.scrollingElement || document.documentElement)
          .scrollTop,
        offsetY: iframe.offsetTop,
      },
    })
  })
