import { PollardAction } from '@finanzcheck/catalyst-pollard'
import { createClientResponse } from '../../utils/pollard'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handlers: Partial<Record<string, (payload: any) => PollardAction>> = {}

export default () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createClientResponse<string, any, any>(({ type, payload }) => {
    const handler = handlers[type]
    if (!handler) {
      return { type }
    }

    return {
      type,
      payload: handler(payload),
    }
  })
